@use 'themed-variables-light-theme-variables' as themed-variables-light;
@use 'themed-variables-dark-theme-variables' as themed-variables-dark;
@use '../focus-ring-tokens' as focus-ring-rokens;
@use 'theme-primitives';

/** PRIVATE */
@mixin _alphaThemeVariables($color-rgb) {
  --theme-alpha-05: rgba(#{$color-rgb}, 5%);
  --theme-alpha-10: rgba(#{$color-rgb}, 10%);
  --theme-alpha-20: rgba(#{$color-rgb}, 20%);
  --theme-alpha-30: rgba(#{$color-rgb}, 30%);
  --theme-alpha-40: rgba(#{$color-rgb}, 40%);
  --theme-alpha-50: rgba(#{$color-rgb}, 50%);
  --theme-alpha-60: rgba(#{$color-rgb}, 60%);
  --theme-alpha-70: rgba(#{$color-rgb}, 70%);
  --theme-alpha-80: rgba(#{$color-rgb}, 80%);
  --theme-alpha-90: rgba(#{$color-rgb}, 90%);
  --theme-alpha-100: rgba(#{$color-rgb}, 100%);
}

@mixin defaultThemeVariables() {
  --theme-50: #{theme-primitives.$blue-dark-50};
  --theme-100: #{theme-primitives.$blue-dark-100};
  --theme-200: #{theme-primitives.$blue-dark-200};
  --theme-300: #{theme-primitives.$blue-dark-300};
  --theme-400: #{theme-primitives.$blue-dark-400};
  --theme-500: #{theme-primitives.$blue-dark-500};
  --theme-600: #{theme-primitives.$blue-dark-600};
  --theme-700: #{theme-primitives.$blue-dark-700};
  --theme-800: #{theme-primitives.$blue-dark-800};
  --theme-900: #{theme-primitives.$blue-dark-900};
  --theme-950: #{theme-primitives.$blue-dark-950};

  --theme-500-rgb: 41, 112, 255;

  --color-utility-blue-dark-hue: 220;
  --theme-hue: 220;

  @include _alphaThemeVariables(var(--theme-500-rgb));
}

@mixin themeColorVariables() {
  // Themes variables have to be included here again, otherwise the theme color variables are not applied
  // Transitive referencing of css variables does not work
  @include themed-variables-light.themed-variables-light;
  @include focus-ring-rokens.themed-focus-ring-tokens;

  &[data-color='premium'] {
    --theme-50: #{theme-primitives.$gray-blue-50};
    --theme-100: #{theme-primitives.$gray-blue-100};
    --theme-200: #{theme-primitives.$gray-blue-200};
    --theme-300: #{theme-primitives.$gray-blue-300};
    --theme-400: #{theme-primitives.$gray-blue-400};
    --theme-500: #{theme-primitives.$gray-blue-500};
    --theme-600: #{theme-primitives.$gray-blue-600};
    --theme-700: #{theme-primitives.$gray-blue-700};

    --theme-500-rgb: 78, 91, 166;

    --theme-hue: 231;

    @include _alphaThemeVariables(var(--theme-500-rgb));
  }

  &[data-color='pink'],
  &[data-color='0'] {
    --theme-50: #{theme-primitives.$rose-50};
    --theme-100: #{theme-primitives.$rose-100};
    --theme-200: #{theme-primitives.$rose-200};
    --theme-300: #{theme-primitives.$rose-300};
    --theme-400: #{theme-primitives.$rose-400};
    --theme-500: #{theme-primitives.$rose-500};
    --theme-600: #{theme-primitives.$rose-600};
    --theme-700: #{theme-primitives.$rose-700};
    --theme-800: #{theme-primitives.$rose-800};
    --theme-900: #{theme-primitives.$rose-900};
    --theme-950: #{theme-primitives.$rose-950};

    --theme-500-rgb: 246, 61, 104;

    --theme-hue: 346;

    @include _alphaThemeVariables(var(--theme-500-rgb));
  }

  &[data-color='denim'],
  &[data-color='1'] {
    @include defaultThemeVariables;
  }

  &[data-color='mint'],
  &[data-color='2'] {
    --theme-50: #{theme-primitives.$teal-50};
    --theme-100: #{theme-primitives.$teal-100};
    --theme-200: #{theme-primitives.$teal-200};
    --theme-300: #{theme-primitives.$teal-300};
    --theme-400: #{theme-primitives.$teal-400};
    --theme-500: #{theme-primitives.$teal-500};
    --theme-600: #{theme-primitives.$teal-600};
    --theme-700: #{theme-primitives.$teal-700};
    --theme-800: #{theme-primitives.$teal-800};
    --theme-900: #{theme-primitives.$teal-900};
    --theme-950: #{theme-primitives.$teal-950};

    --theme-500-rgb: 21, 183, 159;

    --theme-hue: 171;

    @include _alphaThemeVariables(var(--theme-500-rgb));
  }

  &[data-color='purple'],
  &[data-color='3'] {
    --theme-50: #{theme-primitives.$fuchsia-50};
    --theme-100: #{theme-primitives.$fuchsia-100};
    --theme-200: #{theme-primitives.$fuchsia-200};
    --theme-300: #{theme-primitives.$fuchsia-300};
    --theme-400: #{theme-primitives.$fuchsia-400};
    --theme-500: #{theme-primitives.$fuchsia-500};
    --theme-600: #{theme-primitives.$fuchsia-600};
    --theme-700: #{theme-primitives.$fuchsia-700};
    --theme-800: #{theme-primitives.$fuchsia-800};
    --theme-900: #{theme-primitives.$fuchsia-900};
    --theme-950: #{theme-primitives.$fuchsia-950};

    --theme-500-rgb: 212, 68, 241;

    --theme-hue: 290;

    @include _alphaThemeVariables(var(--theme-500-rgb));
  }

  &[data-color='teal'],
  &[data-color='4'] {
    --theme-50: #{theme-primitives.$cyan-50};
    --theme-100: #{theme-primitives.$cyan-100};
    --theme-200: #{theme-primitives.$cyan-200};
    --theme-300: #{theme-primitives.$cyan-300};
    --theme-400: #{theme-primitives.$cyan-400};
    --theme-500: #{theme-primitives.$cyan-500};
    --theme-600: #{theme-primitives.$cyan-600};
    --theme-700: #{theme-primitives.$cyan-700};
    --theme-800: #{theme-primitives.$cyan-800};
    --theme-900: #{theme-primitives.$cyan-900};
    --theme-950: #{theme-primitives.$cyan-950};

    --theme-500-rgb: 6, 174, 212;

    --theme-hue: 191;

    @include _alphaThemeVariables(var(--theme-500-rgb));
  }

  &[data-color='orange'],
  &[data-color='5'] {
    --theme-50: #{theme-primitives.$yellow-50};
    --theme-100: #{theme-primitives.$yellow-100};
    --theme-200: #{theme-primitives.$yellow-200};
    --theme-300: #{theme-primitives.$yellow-300};
    --theme-400: #{theme-primitives.$yellow-400};
    --theme-500: #{theme-primitives.$yellow-500};
    --theme-600: #{theme-primitives.$yellow-600};
    --theme-700: #{theme-primitives.$yellow-700};
    --theme-800: #{theme-primitives.$yellow-800};
    --theme-900: #{theme-primitives.$yellow-900};
    --theme-950: #{theme-primitives.$yellow-950};

    --theme-500-rgb: 234, 170, 8;

    --theme-hue: 43;

    @include _alphaThemeVariables(var(--theme-500-rgb));
  }

  &[data-color='green'],
  &[data-color='6'] {
    --theme-50: #{theme-primitives.$green-light-50};
    --theme-100: #{theme-primitives.$green-light-100};
    --theme-200: #{theme-primitives.$green-light-200};
    --theme-300: #{theme-primitives.$green-light-300};
    --theme-400: #{theme-primitives.$green-light-400};
    --theme-500: #{theme-primitives.$green-light-500};
    --theme-600: #{theme-primitives.$green-light-600};
    --theme-700: #{theme-primitives.$green-light-700};
    --theme-800: #{theme-primitives.$green-light-800};
    --theme-900: #{theme-primitives.$green-light-900};
    --theme-950: #{theme-primitives.$green-light-950};

    --theme-500-rgb: 102, 198, 28;

    --theme-hue: 94;

    @include _alphaThemeVariables(var(--theme-500-rgb));
  }

  &[data-color='violet'],
  &[data-color='7'] {
    --theme-50: #{theme-primitives.$purple-50};
    --theme-100: #{theme-primitives.$purple-100};
    --theme-200: #{theme-primitives.$purple-200};
    --theme-300: #{theme-primitives.$purple-300};
    --theme-400: #{theme-primitives.$purple-400};
    --theme-500: #{theme-primitives.$purple-500};
    --theme-600: #{theme-primitives.$purple-600};
    --theme-700: #{theme-primitives.$purple-700};
    --theme-800: #{theme-primitives.$purple-800};
    --theme-900: #{theme-primitives.$purple-900};
    --theme-950: #{theme-primitives.$purple-950};

    --theme-500-rgb: 122, 90, 248;

    --theme-hue: 252;

    @include _alphaThemeVariables(var(--theme-500-rgb));
  }
}

@mixin themeColorVariablesDark() {
  @include themed-variables-dark.themed-variables-dark;
}
