@mixin gradient-text($gradient) {
  background: $gradient;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin gradient-border($gradient, $border-width, $border-radius) {
  border-radius: $border-radius;

  &::after {
    content: '';
    position: absolute;
    top: calc(-1 * #{$border-width});
    left: calc(-1 * #{$border-width});
    height: calc(100% + 2 * #{$border-width});
    width: calc(100% + 2 * #{$border-width});
    background: $gradient;
    border-radius: calc(#{$border-radius} + #{$border-width});
    z-index: -1;

    @content;
  }
}

@mixin ai-border-animated($border-radius) {
  $gradient: var(--color-ai-gradient-circle);
  $border-width: 3px;

  @include gradient-border($gradient, $border-width, $border-radius) {
    animation: animated-gradient 2s linear infinite;
    background-size: 250% 250%;
    opacity: 0.6;
    filter: blur(2px);

    @content;
  }
}

@keyframes animated-gradient {
  0% {
    background-position: 0 0;
  }

  25% {
    background-position: 100% 0;
  }

  50% {
    background-position: 100% 100%;
  }

  75% {
    background-position: 0 100%;
  }

  100% {
    background-position: 0 0;
  }
}
