@mixin focus-ring-tokens {
  --focus-ring-brand: 0px 0px 0px 4px rgba(41, 112, 255, 24%);
  --focus-ring-brand-shadow-xs: 0px 0px 0px 4px rgba(41, 112, 255, 24%), 0px 1px 2px 0px rgba(16, 24, 40, 5%);
  --focus-ring-brand-shadow-sm: 0px 0px 0px 4px rgba(41, 112, 255, 24%), 0px 1px 2px 0px rgba(16, 24, 40, 6%),
    0px 1px 3px 0px rgba(16, 24, 40, 10%);
  --focus-ring-success: 0px 0px 0px 4px rgba(23, 178, 106, 24%);
  --focus-ring-gray: 0px 0px 0px 4px rgba(152, 162, 179, 14%);
  --focus-ring-gray-secondary: 0px 0px 0px 4px rgba(152, 162, 179, 20%);
  --focus-ring-error: 0px 0px 0px 4px rgba(240, 68, 56, 24%);
  --focus-ring-gray-shadow-xs: 0px 0px 0px 4px rgba(152, 162, 179, 14%), 0px 1px 2px 0px rgba(16, 24, 40, 5%);
  --focus-ring-gray-shadow-sm: 0px 0px 0px 4px rgba(152, 162, 179, 14%), 0px 1px 2px 0px rgba(16, 24, 40, 6%),
    0px 1px 3px 0px rgba(16, 24, 40, 10%);
  --focus-ring-error-shadow-xs: 0px 0px 0px 4px rgba(240, 68, 56, 24%), 0px 1px 2px 0px rgba(16, 24, 40, 5%);

  @include themed-focus-ring-tokens;
}

@mixin themed-focus-ring-tokens {
  // 16, 24, 40 refers to $utility-gray-900
  --focus-ring-theme-shadow-xs: 0px 0px 0px 4px rgba(var(--theme-500-rgb), 0.24), 0px 1px 2px 0px rgba(16, 24, 40, 5%);
  --focus-ring-theme-shadow-sm: 0px 0px 0px 4px rgba(var(--theme-500-rgb), 0.24), 0px 1px 2px 0px rgba(16, 24, 40, 6%),
    0px 1px 3px 0px rgba(16, 24, 40, 10%);
  --focus-ring-theme: 0px 0px 0px 4px rgba(var(--theme-500-rgb), 0.24);
}
