@use '@stsm/styles/mixins/gradient-utils.mixins' as gradient-utils;

$border-radius: var(--radius-xl);

.mdc-snackbar.toast-container {
  --toast-text-color: var(--color-button-primary-fg);

  color: var(--toast-text-color);
  width: fit-content;
  margin-bottom: max(var(--ion-safe-area-bottom), var(--spacing-lg));

  .mdc-snackbar__surface {
    box-shadow: var(--shadow-xs);
    background: var(--color-button-primary-bg);
    min-width: 0;
    border: 1px solid var(--color-button-primary-border);
    border-radius: $border-radius;

    &::before {
      display: none;
    }

    .mdc-snackbar-label {
      padding: var(--spacing-xl);
      padding-right: var(--spacing-md);
    }
  }

  &.generic-component-toast {
    .mdc-snackbar__surface {
      padding: 0;
      overflow: hidden;
      border: none;

      .mat-mdc-snack-bar-label {
        padding: 0;
      }
    }
  }

  &.success-toast {
    i {
      color: var(--color-utility-success-500);
    }
  }

  &.danger-toast {
    i {
      color: var(--color-utility-error-500);
    }
  }

  &.info-toast {
    i {
      color: var(--color-utility-blue-500);
    }
  }

  &.ai-generation-toast,
  &.ai-success-toast {
    --toast-text-color: var(--color-text-primary);

    .mdc-snackbar__surface {
      background: var(--color-bg-primary_alt);
      border: none;
    }
  }

  &.ai-generation-toast {
    @include gradient-utils.ai-border-animated($border-radius);
  }

  &.ai-success-toast {
    @include gradient-utils.gradient-border(var(--color-ai-gradient), 2px, $border-radius);
  }
}
