/*
usage: @include for-max-width(tablet) { *css goes here* }
*/

// max width
$breakpoint-max-width-mobile-small: 374px;
$breakpoint-max-width-mobile: 429px;
$breakpoint-max-width-tablet: 759px;
$breakpoint-max-width-web: 1199px;

// min width
$breakpoint-min-width-mobile: 375px;
$breakpoint-min-width-tablet: 430px;
$breakpoint-min-width-web: 760px;
$breakpoint-min-width-web-large: 1200px;

@mixin for-max-width($size) {
  @if $size == web {
    @media (max-width: $breakpoint-max-width-web) {
      @content;
    }
  } @else if $size == tablet {
    @media (max-width: $breakpoint-max-width-tablet) {
      @content;
    }
  } @else if $size == mobile {
    @media (max-width: $breakpoint-max-width-mobile) {
      @content;
    }
  } @else if $size == mobile-small {
    @media (max-width: $breakpoint-max-width-mobile-small) {
      @content;
    }
  }
}

@mixin for-min-width($size) {
  @if $size == web-large {
    @media (min-width: $breakpoint-min-width-web-large) {
      @content;
    }
  } @else if $size == web {
    @media (min-width: $breakpoint-min-width-web) {
      @content;
    }
  } @else if $size == tablet {
    @media (min-width: $breakpoint-min-width-tablet) {
      @content;
    }
  } @else if $size == mobile {
    @media (min-width: $breakpoint-min-width-mobile) {
      @content;
    }
  }
}
