/* stylelint-disable */

@use 'mixins/redesign-breakpoints.mixin' as breakpoints;
@use 'layout-constants' as layout-constants;

$pageBackground: var(--our-ion-page-bg, var(--color-bg-container));

@mixin page-wrapper-styles {
  border-radius: var(--radius-md);
  border: 1px solid var(--color-border-base);
  // backdrop-filter: var(--backdrop-blur-md);

  box-shadow: 0 -4px 16px 0 rgba(0, 0, 0, 5%);
}

.ion-page:not(.scaffold, ion-app, .ion-page-standalone, .ion-delegate-host) {
  --navbar-left-width: 0;

  overflow: hidden;
  will-change: transform, opacity;

  @include breakpoints.for-min-width(web) {
    --navbar-left-width: #{layout-constants.$navbar-left-width};
  }

  // Tab root page, e.g. library, search etc.
  &.ion-page-tab-root {
    // large screen --> card style
    @include breakpoints.for-min-width(web) {
      @include page-wrapper-styles;

      margin: 16px 16px 16px 0;
      height: calc(100% - 32px);
      width: calc(100% - 16px);
    }

    // small screen --> flat style
    @include breakpoints.for-max-width(tablet) {
      margin: 0;
      height: 100%;
      width: 100%;

      &.ion-page-hidden {
        // all child elements have display: none in the hidden state (see below), thus we need to make sure that the
        // background of the scaffold component is not interfered by any page background
        background: none;
      }
    }
  }

  // routed page in overlay style
  &:not(.ion-page-tab-root) {
    @include breakpoints.for-min-width(web) {
      @include page-wrapper-styles;

      margin: 32px 16px 16px 0;
      height: calc(100% - 48px);
      width: calc(100% - 16px);
    }

    // mobile-like viewport --> bottom sheet style
    @include breakpoints.for-max-width(tablet) {
      margin: 0;
      width: 100%;
      border-top-left-radius: var(--radius-md);
      border-top-right-radius: var(--radius-md);

      // The page is translated 16px up during the page transition.
      // Add 16px border to avoid having an edge at the bottom.
      // When the page is active, this border is outside of the viewport.
      border-bottom: 16px solid $pageBackground;

      // add the height of the bottom border and the  bottom navbar such that the page overlaps the navbar
      height: calc(100% + 16px + #{layout-constants.$navbar-bottom-height});
    }
  }

  display: block;
  position: absolute;
  inset: 0;
  background: $pageBackground;

  // pages that span over the whole width while the page is active (covering the right sidebar)
  &.full-page-route:not(.ion-page-hidden) {
    // --sidebar-width is set dynamically by the scaffold component
    width: calc(100% + var(--sidebar-width) + var(--navbar-left-width) - 32px);
    height: calc(100% - 32px);
    left: calc(-1 * var(--navbar-left-width));
    margin: 16px;

    @include breakpoints.for-max-width(tablet) {
      width: 100%;
      height: calc(100% + 16px + #{layout-constants.$navbar-bottom-height});
      margin: 0;
    }
  }

  // needed to overwrite ionic's default ion-page-hidden class (which also uses !important)
  &.keep-visible {
    display: block !important;
  }

  // hide the content of inactive pages, but not the page itself
  &.ion-page-hidden > * {
    display: none;
  }
}
