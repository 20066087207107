:root {
  --ui-grid-layout-columns: 12;
  --ui-grid-layout-column-gap: var(--spacing-md, 8px);
  --ui-grid-layout-row-gap: var(--spacing-md, 8px);
}

.ui-grid-layout {
  display: grid;
  grid-template-columns: repeat(var(--ui-grid-layout-columns), minmax(0, 1fr));
  column-gap: var(--ui-grid-layout-column-gap);

  // stylelint-disable-next-line declaration-block-no-redundant-longhand-properties
  row-gap: var(--ui-grid-layout-row-gap);
  align-items: var(--ui-grid-layout-align-items, initial);

  // stylelint-disable-next-line declaration-block-no-redundant-longhand-properties
  justify-items: var(--ui-grid-layout-justify-items, initial);

  .ui-grid-layout-column {
    // stylelint-disable declaration-block-no-redundant-longhand-properties
    grid-column-start: var(--ui-grid-layout-column-start, initial);
    grid-column-end: var(--ui-grid-layout-column-end, initial);
    align-self: var(--ui-grid-layout-column-align-self, initial);
    justify-self: var(--ui-grid-layout-column-justify-self, initial);
  }
}
