@use './tokens';
@use 'sass:map';

@use './icon-font/studysmarter-icon-font' as icon-font;

/**
 use this mixin inside a root element, as it contains some root styling

 Variables have to be provided separately, see src/colors/default
 */
@mixin summary-styles($grid_size: 8px) {
  // Use css variables to set paragraph headings
  // Variables will be updated in ts code based on current language
  $deep-dive-heading: var(--summary-deep-dive-heading, 'Deep dive');
  $definition-heading: var(--summary-definition-heading, 'Definition');
  $example-heading: var(--summary-example-heading, 'Example');
  $overview-heading: var(--summary-overview-heading, 'Overview');

  @include tokens.deprecated-font-size-variables;
  @include tokens.deprecated-font-weight-variables;

  // font sizes specific for summaries
  $font-size-summary-subheading: 18px;
  $font-size-quotes-icon: 30px;

  // default text color is text-main
  color: var(--color-text-primary);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.2;
  }

  // Title
  .title-big {
    color: var(--color-text-primary);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-4xl);
    margin: 8 * $grid_size 0 6 * $grid_size 0;
  }

  // Heading
  .title-medium {
    color: var(--color-text-primary);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-2xl);
    margin: 6 * $grid_size 0 3 * $grid_size 0;
  }

  // Subheading
  .title-small {
    color: var(--color-fg-interactive-default);
    font-weight: var(--font-weight-bold);
    font-size: $font-size-summary-subheading;
    margin: 6 * $grid_size 0 3 * $grid_size 0;
  }

  // Normal text
  p,
  .text-default {
    color: var(--color-text-primary);
    font-size: var(--font-size-body-normal);
    line-height: 1.5;
    margin: 0; // needed to override browser default margin for p
  }

  /**
    New paragraph classes (Aug 2021)
   */

  // Hint
  .hint-class {
    position: relative;
    padding: $grid_size;
    padding-left: 4 * $grid_size; // space for absolute icon
    border-top: 1px solid var(--color-border-primary);
    margin: 2 * $grid_size 0 3 * $grid_size;
    color: var(--color-fg-interactive-default);
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-body-small);
    line-height: 1.5;

    > p {
      font-size: inherit; // otherwise would use p rule
      color: var(--color-fg-interactive-default); // otherwise would use p rule
    }

    &::before {
      @include icon-font.icon-font-style;

      content: map.get(icon-font.$studysmarter-icon-font-map, 'hint');
      position: absolute;
      left: 0;
      top: 0.75 * $grid_size;
      font-size: var(--font-size-2xl);
    }
  }

  // Quote
  .quote-class {
    position: relative;
    margin: 4 * $grid_size 0;
    padding-left: 4 * $grid_size; // space for absolute icon
    color: var(--color-text-primary);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-lg);
    font-style: italic;
    line-height: 1.25;

    > p {
      font-size: inherit; // otherwise would use p rule
    }

    &::before {
      @include icon-font.icon-font-style;

      content: map.get(icon-font.$studysmarter-icon-font-map, 'quote-fill');
      color: var(--color-fg-interactive-default);
      opacity: 0.6;
      font-size: $font-size-quotes-icon;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  // Overview
  .overview-class {
    color: var(--color-text-primary);
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-body-normal);
    line-height: 1.5;
    border: 1px solid var(--color-border-primary);
    border-radius: $grid_size;
    padding-top: 3 * $grid_size;
    padding-right: 3 * $grid_size;
    padding-bottom: 3 * $grid_size;
    margin: 2 * $grid_size 0;

    & > li::marker {
      color: var(--color-fg-interactive-default);
    }

    &::before {
      display: block;
      margin: 0 0 $grid_size;
      font-weight: var(--font-weight-bold);
      font-size: var(--font-size-caption);
      line-height: 1.2;
      color: var(--color-fg-interactive-default);
      content: $overview-heading;
    }
  }

  // Key takeaways
  .key-takeaways-class {
    border: 1px solid var(--color-border-primary);
    border-radius: $grid_size;
    margin: 2 * $grid_size 0;

    & > h2 {
      padding-left: 3 * $grid_size;
    }

    & > ul {
      color: var(--color-text-primary);
      font-weight: var(--font-weight-normal);
      font-size: var(--font-size-body-normal);
      line-height: 1.5;
      margin: 0 3 * $grid_size 3 * $grid_size 0;
    }
  }

  // Definition / Example
  .definition-class,
  .example-class {
    position: relative;
    display: block;
    padding: 4 * $grid_size 0 $grid_size 4 * $grid_size;
    margin: 2 * $grid_size 0;
    color: var(--color-text-primary);
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-body-normal);
    line-height: 1.5;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 0.5 * $grid_size;
      width: 0.5 * $grid_size;
      height: 100%;
      content: '';
    }

    &::after {
      position: absolute;
      top: $grid_size;
      left: 4 * $grid_size;
      font-weight: var(--font-weight-bold);
      font-size: var(--font-size-caption);
      line-height: 1.2;
    }

    p {
      font-size: inherit; // otherwise would use p rule
    }
  }

  // Example
  .example-class {
    &::before {
      background-color: var(--color-fg-interactive-default);
    }

    &::after {
      color: var(--color-fg-interactive-default);
      content: $example-heading;
    }
  }

  // Definition
  .definition-class {
    &::before {
      background-color: var(--color-utility-rose-700);
    }

    &::after {
      color: var(--color-utility-rose-700);
      content: $definition-heading;
    }
  }

  // Deep dive
  .deep-dive-class {
    position: relative;
    display: block;
    padding: 3 * $grid_size 2 * $grid_size 3 * $grid_size 5 * $grid_size;
    border: 1px solid var(--color-border-primary);
    border-radius: $grid_size;
    margin: 2 * $grid_size 0;
    color: var(--color-text-primary);
    background-color: var(--color-bg-primary);
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-body-normal);
    line-height: 1.5;

    &::before {
      display: block;
      color: var(--color-fg-interactive-default);
      font-weight: var(--font-weight-bold);
      font-size: var(--font-size-caption);
      line-height: 1.2;
      content: $deep-dive-heading;
      margin-bottom: $grid_size;
    }

    &::after {
      @include icon-font.icon-font-style;

      position: absolute;
      top: 3 * $grid_size;
      left: 1.5 * $grid_size;
      color: var(--color-fg-interactive-default);
      font-size: var(--font-size-2xl);
      content: map.get(icon-font.$studysmarter-icon-font-map, 'diver');
    }

    p {
      font-size: inherit; // otherwise would use p rule
    }
  }

  // Code
  pre {
    white-space: pre;
    overflow-x: auto;
    background: var(--color-alpha-black-05);
    color: var(--color-text-primary);
    border-radius: var(--radius-md, var(--spacing-lg));
    font-size: var(--font-size-body-small);
    padding: var(--spacing-lg);
    font-family: monospace !important;
  }

  /**
    Old paragraph classes (before Aug 2021)
   */

  .quote {
    font-style: italic;
    font-size: var(--font-size-lg);
  }

  .hint {
    color: var(--color-fg-interactive-default);
    font-size: var(--font-size-body-small);
  }

  .insider-hint {
    color: var(--color-utility-rose-500);
    font-size: var(--font-size-body-small);
  }

  // miscellaneous

  .cta-banner {
    height: 0;
    display: none;
  }

  ul,
  ol {
    // prevent list items from getting line break after bullet
    list-style-position: outside !important;
  }

  table {
    color: inherit;
    border-collapse: collapse;

    td,
    th {
      border: 1px solid var(--color-border-primary);

      &.fr-selected-cell {
        border: 1px double var(--color-border-interactive-default);
      }
    }

    th {
      background: var(--color-bg-secondary);
    }

    td {
      padding: 5px;
    }
  }

  .creator-comment {
    display: none;
  }

  // link text color
  a {
    color: var(--color-fg-interactive-default);
  }
}
