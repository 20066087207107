@font-face {
  font-family: 'Proxima Nova';
  font-display: swap;
  src: url('./fonts/Proxima_Nova_Regular.otf') format('opentype');

  // stylelint-disable-next-line
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  font-display: swap;
  src: url('./fonts/Proxima_Nova_Semibold.otf') format('opentype');

  // stylelint-disable-next-line
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans 3';
  src: url('./fonts/SourceSans3-VariableFont_wght.ttf') format('truetype');
  font-weight: 1 999;
}

@font-face {
  font-family: Outfit;
  src: url('./fonts/Outfit-VariableFont_wght.ttf') format('truetype');
  font-weight: 1 999;
}

@font-face {
  font-family: 'Noto Color Emoji';
  src: url('./fonts/Noto_Color_Emoji.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
