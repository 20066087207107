@mixin scrollbar-styles($size: 8px, $bg: transparent, $color: var(--color-alpha-black-10)) {
  @media (pointer: fine) {
    ::-webkit-scrollbar {
      width: $size;
      height: $size;
    }

    ::-webkit-scrollbar-track {
      background: $bg;
    }

    ::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 0.5 * $size;
    }

    *:hover::-webkit-scrollbar-thumb {
      background: $color;
    }

    // Standard version (Firefox only for now)
    scrollbar-color: $color $bg;
  }
}
