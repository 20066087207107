@mixin shadow-tokens {
  --shadow-xs: 0px 1px 2px 0px rgba(16, 24, 40, 5%);
  --shadow-sm: 0px 1px 2px 0px rgba(16, 24, 40, 6%), 0px 1px 3px 0px rgba(16, 24, 40, 10%);
  --shadow-md: 0px 2px 4px -2px rgba(16, 24, 40, 6%), 0px 4px 8px -2px rgba(16, 24, 40, 10%);
  --shadow-lg: 0px 4px 6px -2px rgba(16, 24, 40, 3%), 0px 12px 16px -4px rgba(16, 24, 40, 8%);
  --shadow-xl: 0px 8px 8px -4px rgba(16, 24, 40, 3%), 0px 20px 24px -4px rgba(16, 24, 40, 8%);
  --shadow-2xl: 0px 24px 48px -12px rgba(16, 24, 40, 18%);
  --shadow-3xl: 0px 32px 64px -12px rgba(16, 24, 40, 14%);

  --shadow-premium: 0px 2px 0px 0px rgba(255, 255, 255, 20%) inset, 0px 1px 6px 0px rgba(255, 255, 255, 40%) inset;
}

@mixin deprecated-box-shadows-light-theme {
  $box-shadow-color: var(--color-bg-quaternary);
  $box-shadow-color-rgb: 210, 217, 224;

  --box-shadow-popup: 0 0 20px #{$box-shadow-color};
  --box-shadow-card: 0 0 10px rgba(#{$box-shadow-color-rgb}, 0.5); // often
  --box-shadow-up: 0 -4px 10px rgba(#{$box-shadow-color-rgb}, 0.2); // 4x
  --box-shadow-down: 0 4px 10px rgba(#{$box-shadow-color-rgb}, 0.3); // 6x
}

@mixin deprecated-box-shadows-dark-theme {
  --box-shadow-popup: none;
  --box-shadow-card: none;
  --box-shadow-up: none;
  --box-shadow-down: none;
}
