@use 'sass:color';

@function rgbListFromColor($color) {
  @return color.red($color), color.green($color), color.blue($color);
}

@mixin colors() {
  // Ionic color variables (shade/tint calculated by ionic tool https://ionicframework.com/docs/theming/color-generator)

  /** primary **/
  --ion-color-primary: #155eef; // var(--color-utility-blue-dark-600)
  --ion-color-primary-rgb: #{rgbListFromColor(#155eef)};
  --ion-color-primary-contrast: #fff; // var(--color-text-light-stable)
  --ion-color-primary-contrast-rgb: #{rgbListFromColor(#fff)};
  --ion-color-primary-shade: #1253d2;
  --ion-color-primary-tint: #2c6ef1;

  /** danger **/
  --ion-color-danger: #d92d20; // var(--color-bg-error-solid)
  --ion-color-danger-rgb: #{rgbListFromColor(#d92d20)};
  --ion-color-danger-contrast: #fff;
  --ion-color-danger-contrast-rgb: #{rgbListFromColor(#fff)};
  --ion-color-danger-shade: #bf281c;
  --ion-color-danger-tint: #dd4236;

  /**
    Ionic Application Colors
    https://ionicframework.com/docs/theming/themes
    https://ionicframework.com/docs/theming/advanced

    step colors created via https://ionicframework.com/docs/theming/themes#stepped-color-generator based on the
    background-color and text-color
  **/
  --ion-background-color: #fff; // var(--color-bg-primary)
  --ion-background-color-rgb: #{rgbListFromColor(#fff)};

  --ion-text-color: #101828; // var(--color-text-primary)
  --ion-text-color-rgb: #{rgbListFromColor(#101828)};

  --ion-text-color-step-50: #1c2433;
  --ion-text-color-step-100: #282f3e;
  --ion-text-color-step-150: #343b48;
  --ion-text-color-step-200: #404653;
  --ion-text-color-step-250: #4c525e;
  --ion-text-color-step-300: #585d69;
  --ion-text-color-step-350: #646973;
  --ion-text-color-step-400: #70747e;
  --ion-text-color-step-450: #7c8089;
  --ion-text-color-step-500: #888c94;
  --ion-text-color-step-550: #93979e;
  --ion-text-color-step-600: #9fa3a9;
  --ion-text-color-step-650: #abaeb4;
  --ion-text-color-step-700: #b7babf;
  --ion-text-color-step-750: #c3c5c9;
  --ion-text-color-step-800: #cfd1d4;
  --ion-text-color-step-850: #dbdcdf;
  --ion-text-color-step-900: #e7e8ea;
  --ion-text-color-step-950: #f3f3f4;

  --ion-background-color-step-50: #f3f3f4;
  --ion-background-color-step-100: #e7e8ea;
  --ion-background-color-step-150: #dbdcdf;
  --ion-background-color-step-200: #cfd1d4;
  --ion-background-color-step-250: #c3c5c9;
  --ion-background-color-step-300: #b7babf;
  --ion-background-color-step-350: #abaeb4;
  --ion-background-color-step-400: #9fa3a9;
  --ion-background-color-step-450: #93979e;
  --ion-background-color-step-500: #888c94;
  --ion-background-color-step-550: #7c8089;
  --ion-background-color-step-600: #70747e;
  --ion-background-color-step-650: #646973;
  --ion-background-color-step-700: #585d69;
  --ion-background-color-step-750: #4c525e;
  --ion-background-color-step-800: #404653;
  --ion-background-color-step-850: #343b48;
  --ion-background-color-step-900: #282f3d;
  --ion-background-color-step-950: #1c2433;

  --ion-font-family: 'Source Sans 3', sans-serif;
}

@mixin colorsDarkTheme() {
  // Ionic application colors
  --ion-background-color: #0c111d; // var(--color-bg-primary)
  --ion-background-color-rgb: #{rgbListFromColor(#0c111d)};

  --ion-text-color: #f5f5f6; // var(--color-text-primary)
  --ion-text-color-rgb: #{rgbListFromColor(#f5f5f6)};

  // created via https://ionicframework.com/docs/theming/themes#stepped-color-generator based on the background-color
  // and text-color above
  --ion-text-color-step-50: #e9eaeb;
  --ion-text-color-step-100: #dedee0;
  --ion-text-color-step-150: #d2d3d5;
  --ion-text-color-step-200: #c6c7cb;
  --ion-text-color-step-250: #bbbcc0;
  --ion-text-color-step-300: #afb1b5;
  --ion-text-color-step-350: #a3a5aa;
  --ion-text-color-step-400: #989a9f;
  --ion-text-color-step-450: #8c8e94;
  --ion-text-color-step-500: #81838a;
  --ion-text-color-step-550: #75787f;
  --ion-text-color-step-600: #696c74;
  --ion-text-color-step-650: #5e6169;
  --ion-text-color-step-700: #52555e;
  --ion-text-color-step-750: #464a53;
  --ion-text-color-step-800: #3b3f48;
  --ion-text-color-step-850: #2f333e;
  --ion-text-color-step-900: #232833;
  --ion-text-color-step-950: #181c28;

  --ion-background-color-step-50: #181c28;
  --ion-background-color-step-100: #232833;
  --ion-background-color-step-150: #2f333e;
  --ion-background-color-step-200: #3b3f48;
  --ion-background-color-step-250: #464a53;
  --ion-background-color-step-300: #52555e;
  --ion-background-color-step-350: #5e6169;
  --ion-background-color-step-400: #696c74;
  --ion-background-color-step-450: #75787f;
  --ion-background-color-step-500: #81838a;
  --ion-background-color-step-550: #8c8e94;
  --ion-background-color-step-600: #989a9f;
  --ion-background-color-step-650: #a3a5aa;
  --ion-background-color-step-700: #afb1b5;
  --ion-background-color-step-750: #bbbcc0;
  --ion-background-color-step-800: #c6c7cb;
  --ion-background-color-step-850: #d2d3d5;
  --ion-background-color-step-900: #dedee0;
  --ion-background-color-step-950: #e9eaeb;
}
