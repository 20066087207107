[uiInput] {
  outline: none;
  border: 0;
  width: 100%;
  font: var(--text-md-regular);
  margin: 0;
  padding: 0;
  background: transparent;
  color: var(--color-text-primary);
  text-align: left;

  &::placeholder {
    font: var(--text-md-regular);
    color: var(--color-text-placeholder);
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    background-clip: text;
    -webkit-text-fill-color: var(--color-text-primary);
    caret-color: var(--color-text-primary);
  }

  &.ui-small {
    height: 34px;
    font: var(--text-sm-regular);
  }

  &.ui-disabled {
    color: var(--color-text-disabled);
    cursor: not-allowed;

    &:-webkit-autofill {
      -webkit-text-fill-color: var(--color-text-disabled);
    }
  }
}
