// stylelint-disable

@mixin var-fixes-and-extensions {
  --dark-mode-only-border: none;
  --color-border-secondary_alt: var(--color-border-secondary);

  // temp fix for performance
  --color-bg-container: var(--color-bg-body);

  // legacy stuff
  --color-legacy-background-fade-backdrop-default: #21334566;
  --color-legacy-background-fade-backdrop-bold: #213345bf;
}

@mixin dark-var-fixes-and-extensions {
  --dark-mode-only-border: 1px solid var(--color-border-primary);
  --color-border-secondary_alt: var(--color-border-primary);

  // temp fix for performance
  --color-bg-container: var(--color-bg-body);

  // legacy stuff
  --color-legacy-background-fade-backdrop-default: #09162a66;
  --color-legacy-background-fade-backdrop-bold: #09162abf;
}
