@mixin deprecated-font-size-variables($baseSize: 1rem) {
  // 1rem = 10px
  --font-size-2xs: #{1 * $baseSize}; // 10px
  $font-size-xs: #{1.3 * $baseSize}; // 13px
  $font-size-sm: #{1.5 * $baseSize}; // 15px
  $font-size-md: #{1.7 * $baseSize}; // 17px
  --font-size-lg: #{2 * $baseSize}; // 20px
  --font-size-xl: #{2.2 * $baseSize}; // 22px
  --font-size-2xl: #{2.4 * $baseSize}; // 24px
  --font-size-3xl: #{2.8 * $baseSize}; // 28px
  --font-size-4xl: #{3.2 * $baseSize}; // 32px
  --font-size-5xl: #{3.6 * $baseSize}; // 36px
  --font-size-6xl: #{4 * $baseSize}; // 40px
  --font-size-7xl: #{4.6 * $baseSize}; // 46px
  --font-size-8xl: #{5 * $baseSize}; // 50px

  --font-size-body-small: #{$font-size-sm};
  --font-size-body-normal: #{$font-size-md};
  --font-size-caption: #{$font-size-xs};
}

@mixin icon-size-variables($baseSize: 1rem) {
  --icon-size-xxs: #{1.2 * $baseSize}; // 12px
  --icon-size-xs: #{1.6 * $baseSize}; // 16px
  --icon-size-sm: #{2 * $baseSize}; // 20px
  --icon-size-md: #{2.4 * $baseSize}; // 24px
  --icon-size-lg: #{3.2 * $baseSize}; // 32px
}

@mixin deprecated-font-weight-variables {
  --font-weight-normal: 400;
  --font-weight-bold: 600;
}

@mixin font-family-variables {
  --font-family: 'Source Sans 3', sans-serif;
}
