@use '@stsm/styles/colors/theme-color-util';
@use '@stsm/styles/icon-font/studysmarter-icon-font' as icon-font;
@use 'mixins/dark-mode.mixins' as dark-mode;
@use 'mixins/redesign-breakpoints.mixin' as breakpoints;

.themed {
  @include theme-color-util.themeColorVariables;
}

@include dark-mode.global {
  .themed {
    @include theme-color-util.themeColorVariablesDark;
  }
}

$themed-text-accent: var(--color-utility-theme-700);
$themed-icon-accent: var(--color-utility-theme-500);
$themed-background-subtle: var(--color-utility-theme-100);
$themed-background-subtler: var(--color-utility-theme-50);

/*
 * Colored text
 *
 * [themedText]
 */
.themed-text {
  color: $themed-text-accent;

  // different icon color
  @include icon-font.icon-selector {
    color: $themed-icon-accent;
  }

  * {
    @include icon-font.icon-selector {
      color: $themed-icon-accent;
    }
  }
}

/*
 * Background
 *
 * [themedBackground]
 */
.themed-background {
  --background: var(--color-bg-theme-solid);
  --background-activated: var(--color-bg-theme-solid_hover);
  --color: var(--color-text-primary_on-theme);
  --color-activated: var(--color);

  // For ion-toggle
  --track-background-checked: var(--background);

  &:not(ion-toggle) {
    background: var(--background);
    color: var(--color);
  }
}

/*
 * Subtle background
 *
 * [themedBackgroundSubtle]
 */
.themed-background-subtle {
  background: $themed-background-subtle;
}

/*
 * Subtler background
 *
 * [themedBackgroundSubtler]
 */
.themed-background-subtler {
  background: $themed-background-subtler;
}

/*
 * Border
 *
  * [themedBorder]
 */
.themed-border {
  border: 1px solid var(--color-border-theme);
}

/*
 * Border
 *
  * [themedBorderSolid]
 */
.themed-border-solid {
  border: 1px solid var(--color-border-theme-solid);
}

/**
 * utility css class - not covered by directive, just use directly
 */
.themed-background-gradient-on-mobile {
  @include breakpoints.for-max-width(tablet) {
    // stylelint-disable-next-line
    background-image: linear-gradient(to bottom, var(--color-bg-page-theme), var(--color-bg-body));
  }
}
