@use './mixins/redesign-breakpoints.mixin' as breakpoints;

.default-responsive-inset {
  @include breakpoints.for-max-width(tablet) {
    padding: 0 var(--spacing-xl);
    --padding-start: var(--spacing-xl);
    --padding-end: var(--spacing-xl);
  }

  @include breakpoints.for-min-width(web) {
    padding: 0 var(--spacing-3xl);
    --padding-start: var(--spacing-3xl);
    --padding-end: var(--spacing-3xl);
  }
}
