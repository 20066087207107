%code-snippet-styles {
  background: var(--color-alpha-black-05);
  color: var(--color-text-primary);
  border-radius: var(--radius-md, var(--spacing-lg));
  font-size: var(--font-size-body-small);
  padding: var(--spacing-lg);
  font-family: monospace !important;
}

%code-snippet-default {
  @extend %code-snippet-styles;

  white-space: pre;
  overflow-x: auto;
}

%code-snippet-compact {
  @extend %code-snippet-styles;

  font-size: var(--font-size-caption);
  width: fit-content;
}
