div.cdk-overlay-container {
  z-index: 4;
}

.mat-list-text,
.mat-list,
.mat-nav-list {
  padding: 0 !important;
}

.mat-tab-body-wrapper {
  height: 100%;
}

.mat-input-placeholder-wrapper {
  font-size: var(--font-size-body-small);
}

.mat-input-placeholder {
  color: var(--color-text-placeholder);
}

.mat-divider {
  width: auto !important;
  position: initial !important;
}

mat-icon.material-icons,
mat-icon.material-icons.mat-icon {
  width: 1em;
  height: 1em;
}

.cdk-program-focused .mat-button-focus-overlay {
  opacity: 0 !important;
}

.datepicker__calendar__nav,
.datepicker__calendar__content,
.mat-select-value-text {
  color: var(--color-text-secondary);
}

.mat-autocomplete-panel {
  width: min-content;
}

mat-tab-body {
  overflow: hidden;
}

.mat-tab-body-content {
  overflow: hidden !important;
  height: auto !important;
}

mat-expansion-panel {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

span.mat-form-field-label-wrapper label.mat-form-field-label {
  color: var(--color-text-quarterary);
}

div.mat-select-arrow {
  color: var(--color-fg-tertiary);
}

div.mat-select-panel {
  background: var(--color-bg-dropdown);

  .mat-option {
    color: var(--color-text-primary);

    &.mat-selected {
      &:not(.mat-option-multiple) {
        background: var(--color-bg-primary_hover);
      }

      &:not(.mat-option-disabled) {
        color: var(--color-fg-interactive-default);
      }
    }

    &:hover {
      &:not(.mat-option-disabled) {
        background: var(--color-bg-primary_hover);
      }
    }
  }
}

.mat-drawer-content {
  color: var(--color-text-primary);
}
