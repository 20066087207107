@use '@stsm/styles/icon-font/studysmarter-icon-font' as icon-font;
@use 'sass:map';
@use '@stsm/styles/placeholders/code-snippet.placeholder';

.fr-wrapper ::-moz-selection {
  color: inherit !important;
}

.fr-wrapper ::selection {
  color: inherit !important;
}

.fr-tooltip {
  white-space: nowrap;
}

.fr-placeholder {
  font-family: var(--font-family) !important;
}

// For some reason the editImage popup in the flashcard creation has the class fr-active AND
// fr-hidden which results in the fact that the popup is invisible (opacity: 0). As temporary
// workaround we add this global style to force the opacity to 1 if the fr-active class is set.
.fr-popup.fr-active {
  opacity: 1 !important;
}

.fr-emoticon.fr-emoticon-img {
  padding-left: 20px;
}

.fr-toolbar.fr-bottom.fr-toolbar-open .fr-newline {
  display: none;
}

app-multiple-choice-flashcard {
  .fr-view {
    overflow-x: auto !important;
  }
}

.fr-view {
  // Code Snippet
  pre {
    @extend %code-snippet-compact;

    word-wrap: break-word !important;
    overflow: visible !important;
    overflow-x: auto !important;
    width: 100%;
  }
}

// images used in the froala wrapper and the preview of standalone editor
app-flashcard-text-input-field img,
.is-standalone-preview img,
app-froala-wrapper .fr-view img {
  &.sketch-image {
    margin: 0 5px !important;
    width: auto !important;
  }

  &.fr-fic:not(.Wirisformula) {
    max-height: 100px;
    object-fit: contain;
    border: 1px solid var(--color-border-primary);
    border-radius: var(--radius-xs);
  }

  &.fr-dii {
    width: auto;
    height: auto;
    padding: 0;
  }
}

// adjusts popup size to fit in parent
.fr-popup .fr-icon-container {
  min-width: 260px;
  max-height: 160px;
}

// breaks popup tabs  in multiple lines
.fr-tabs.fr-tabs-scroll {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

// Styles for the special-character-category in popup for notes
.fr-popup .fr-buttons.fr-tabs .fr-special-character-category {
  margin: 0 !important;

  span {
    display: inline-flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}

.app-icon-autoplay,
.app-icon-arrows-alt,
.app-icon-exchange,
.app-icon-code,
.app-icon-arrow-left,
.app-icon-header,
.app-icon-bars,
.app-icon-unlink,
.app-icon-magic,
.app-icon-commenting,
.app-icon-tint,
.app-icon-ellipsis-v,
.app-icon-list-ul,
.app-icon-list-ol,
.app-icon-smile-o,
.app-icon-outdent,
.app-icon-indent,
.app-icon-paint-brush,
.app-icon-table,
.app-icon-video-camera,
.app-icon-minus,
.app-icon-dollar-sign,
.app-icon-text-height,
.app-icon-arrows-v,
.app-icon-align-justify {
  &::before {
    font-family: '#{icon-font.$studysmarter-icon-font-fontvar}' !important;
  }
}

.app-icon-autoplay {
  &::before {
    content: map.get(icon-font.$studysmarter-icon-font-map, 'auto-play');
  }
}

.app-icon-arrows-alt {
  &::before {
    content: map.get(icon-font.$studysmarter-icon-font-map, 'frame-size');
  }
}

.app-icon-unlink {
  &::before {
    content: map.get(icon-font.$studysmarter-icon-font-map, 'link-broken');
  }
}

.app-icon-code {
  &::before {
    content: map.get(icon-font.$studysmarter-icon-font-map, 'code-view');
  }
}

.app-icon-exchange {
  &::before {
    content: map.get(icon-font.$studysmarter-icon-font-map, 'edit');
  }
}

.app-icon-arrow-left {
  &::before {
    content: map.get(icon-font.$studysmarter-icon-font-map, 'arrow-back');
  }
}

.app-icon-header {
  &::before {
    content: map.get(icon-font.$studysmarter-icon-font-map, 'table-header');
  }
}

.app-icon-bars {
  &::before {
    content: map.get(icon-font.$studysmarter-icon-font-map, 'table-row');
  }

  &.fa-rotate-90 {
    transform: rotate(0deg);

    &::before {
      content: map.get(icon-font.$studysmarter-icon-font-map, 'table-column');
    }
  }
}

.app-icon-magic {
  &::before {
    content: map.get(icon-font.$studysmarter-icon-font-map, 'image-border');
  }
}

.app-icon-commenting {
  &::before {
    content: map.get(icon-font.$studysmarter-icon-font-map, 'image-caption');
  }
}

.app-icon-tint {
  &::before {
    content: map.get(icon-font.$studysmarter-icon-font-map, 'text-color');
  }
}

.app-icon-ellipsis-v {
  &::before {
    content: map.get(icon-font.$studysmarter-icon-font-map, 'ellipsis-horizontal-fill');
  }
}

.app-icon-list-ul {
  &::before {
    content: map.get(icon-font.$studysmarter-icon-font-map, 'list-unordered');
  }
}

.app-icon-list-ol {
  &::before {
    content: map.get(icon-font.$studysmarter-icon-font-map, 'list-ordered');
  }
}

.app-icon-smile-o {
  &::before {
    content: map.get(icon-font.$studysmarter-icon-font-map, 'smiley-good');
  }
}

.app-icon-outdent {
  &::before {
    content: map.get(icon-font.$studysmarter-icon-font-map, 'decrease-indent');
  }
}

.app-icon-indent {
  &::before {
    content: map.get(icon-font.$studysmarter-icon-font-map, 'increase-indent');
  }
}

.app-icon-paint-brush {
  &::before {
    content: map.get(icon-font.$studysmarter-icon-font-map, 'highlight');
  }
}

.app-icon-table {
  &::before {
    content: map.get(icon-font.$studysmarter-icon-font-map, 'grid');
  }
}

.app-icon-video-camera {
  &::before {
    content: map.get(icon-font.$studysmarter-icon-font-map, 'video');
  }
}

.app-icon-minus {
  &::before {
    content: map.get(icon-font.$studysmarter-icon-font-map, 'remove');
  }
}

.app-icon-dollar-sign {
  &::before {
    content: map.get(icon-font.$studysmarter-icon-font-map, 'special-character');
  }
}

.app-icon-text-height {
  &::before {
    content: map.get(icon-font.$studysmarter-icon-font-map, 'text-size');
  }
}

.app-icon-arrows-v {
  &::before {
    content: map.get(icon-font.$studysmarter-icon-font-map, 'line-height');
  }
}

.app-icon-align-justify {
  &::before {
    content: map.get(icon-font.$studysmarter-icon-font-map, 'menu');
  }
}

.fr-toolbar-open {
  .app-icon-ellipsis-v {
    color: var(--color-fg-interactive-default);
  }
}

.fr-toolbar .fr-command.fr-btn i {
  &.app-icon-list-ul,
  &.app-icon-list-ol {
    margin-right: 0;
  }
}

.fr-popup .fr-buttons {
  button {
    width: 36px;
    height: 36px !important;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    margin-left: 2px !important;
    margin-right: 2px !important;

    &.fr-hidden {
      display: none !important;
    }

    i {
      margin: var(--spacing-md) !important;
      font-size: var(--font-size-xl) !important;
    }
  }

  .fr-dropdown-list {
    i {
      font-size: var(--font-size-xl);
    }
  }
}

.fr-popup .fr-command.fr-btn {
  &.fr-active {
    i {
      color: var(--color-fg-interactive-default);
    }
  }
}

.fr-box.fr-basic .fr-wrapper {
  border-color: var(--color-border-primary) !important;
}

app-smaller-summary-wrapper,
app-froala-wrapper {
  .fr-box.fr-basic .fr-wrapper {
    border-color: var(--color-border-primary) !important;
    border-bottom: none !important;

    .fr-placeholder {
      line-height: var(--font-size-2xl) !important;
      color: var(--color-text-quarterary);
      font-size: var(--font-size-body-normal) !important;
      white-space: normal;
      -webkit-line-clamp: 2;
    }

    .fr-element {
      font-size: var(--font-size-body-normal) !important;

      &.fr-view {
        p,
        li,
        em,
        strong,
        span,
        u {
          font-size: var(--font-size-body-normal) !important;
        }
      }
    }
  }

  .froala-focused.fr-box.fr-basic .fr-wrapper {
    border-color: var(--color-border-interactive-default) !important;
    transition-property: border-color, opacity, height;
    transition-duration: 0.2s;
    transition-timing-function: ease-in;
  }

  .fr-toolbar {
    transition-property: opacity, border-color, height;
    transition-duration: 0.1s;
    transition-timing-function: ease-in;
    border: 1px solid var(--color-border-primary);

    .fr-btn-wrap {
      margin: auto 2px;

      button:first-child {
        display: flex;
        place-items: center;

        i {
          margin: auto var(--spacing-xs);
        }
      }
    }

    .fr-command.fr-btn.fr-dropdown.fr-options {
      position: relative;

      &::after {
        right: 15px;
        top: 12px;
        border: none !important;
        font-family: '#{icon-font.$studysmarter-icon-font-fontvar}' !important;
        content: map.get(icon-font.$studysmarter-icon-font-map, 'chevron-down') !important;
      }
    }
  }

  .fr-command.fr-btn {
    &.fr-active {
      background-color: var(--color-bg-active);
      border-radius: var(--radius-xs);

      i {
        color: var(--color-fg-interactive-default);
      }
    }
  }

  .fr-toolbar .fr-command.fr-btn.fr-open:not(:hover, :focus, :active) {
    background-color: var(--color-bg-alpha-brand-05) !important;
    border-radius: var(--radius-xs);
  }

  .fr-popup .fr-color-set.fr-selected-set .app-icon-eraser {
    font-size: var(--font-size-lg);
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
  }

  .fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-selection,
  .fr-command.fr-btn.fr-dropdown:not(.fr-options) {
    &::after {
      display: none;
    }
  }

  .fr-dropdown-list {
    i {
      font-size: var(--font-size-xl);
    }
  }

  .fr-toolbar .fr-command.fr-btn.fr-dropdown i {
    margin: var(--spacing-md) !important;
  }

  .fr-toolbar .fr-more-toolbar {
    height: auto !important;
    transition: max-height 0.5s;
    max-height: 0;

    button {
      margin: var(--spacing-xxs);
    }

    &.fr-expanded {
      max-height: 100px;
    }
  }

  .fr-popup .fr-command.fr-btn {
    &.fr-special-character-category {
      margin: 0 !important;
    }

    > span {
      display: inline-flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
    }

    &.fr-dropdown::after {
      display: none;
    }

    &.fr-active-tab,
    &.fr-active {
      background-color: var(--color-bg-alpha-brand-05) !important;
    }
  }
}
