@use '@stsm/styles/summary-styles';

.fr-view.fr-print-content {
  @include summary-styles.summary-styles;

  * {
    font-family: Arial, sans-serif;
  }
}

@media print {
  .fr-view {
    -webkit-print-color-adjust: exact;

    table {
      border: none;
      border-collapse: collapse;
      empty-cells: show;
      max-width: 100%;
      page-break-before: auto;
      page-break-inside: avoid;

      td {
        min-width: 5px;
        page-break-inside: avoid;
      }

      tr {
        page-break-before: auto;
        page-break-inside: avoid;
      }
    }
  }
}

@page {
  margin: 11mm 17mm !important;
}
