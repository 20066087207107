@mixin imageBackgroundShim() {
  background: var(--color-fg-white);
  border-radius: var(--radius-md);
  padding: var(--spacing-xs);
}

@mixin implementations() {
  // FROALA CONTENT
  // formulas
  .Wirisformula,
  .formula {
    filter: invert(1);
  }

  // sketches

  img.sketch-image {
    &:not(img.Wirisformula, img.formula) {
      @include imageBackgroundShim;
    }
  }

  app-sketch {
    app-color-picker {
      --color-background: var(--color-fg-white);
    }
  }

  // DOCUMENT VIEWER
  app-document-viewer-page {
    app-color-picker {
      --color-background: var(--color-fg-white);
    }
  }

  // COMPANY/UNIVERSITY LOGOS
  app-company-profiles-carousel {
    .card img {
      @include imageBackgroundShim;
    }
  }
}
