/*!
 * Copied from
 * froala_editor v4.0.8 (https://www.froala.com/wysiwyg-editor)
 * removed all unrelated to color
 * and switched to color variables
 */

@mixin froalaColors() {
  --froala-button-text: var(--color-text-secondary);
  --froala-change-highlight-color: var(--color-utility-yellow-200);

  .fr-box.fr-basic .fr-element {
    color: var(--color-text-primary);
  }

  .fr-popup .fr-image-upload-layer:hover {
    background: var(--color-bg-primary);
  }

  .fr-box a.fr-floating-btn {
    box-shadow:
      0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 1px 5px 0 rgba(0, 0, 0, 0.12);
    background: var(--color-bg-modal);
    color: var(--froala-button-text);
  }
  .fr-box a.fr-floating-btn svg {
    fill: var(--froala-button-text);
  }
  .fr-box a.fr-floating-btn:hover {
    background: var(--color-bg-primary_hover);
  }
  .fr-box a.fr-floating-btn:hover svg {
    fill: var(--froala-button-text);
  }

  .fr-wrapper .fr-placeholder {
    color: var(--color-text-quarterary);
  }

  .fr-wrapper ::-moz-selection {
    background: Highlight;
    color: HighlightText;
  }

  .fr-wrapper ::selection {
    background: Highlight;
    color: HighlightText;
  }

  .fr-box.fr-basic .fr-wrapper {
    background: var(--color-bg-primary);
    border: 1px solid var(--color-border-primary);
    border-bottom-color: var(--color-border-primary);
  }

  @media (min-width: 992px) {
    .fr-box.fr-document .fr-wrapper {
      background: var(--color-bg-primary);
    }
    .fr-box.fr-document .fr-wrapper .fr-element {
      background: var(--color-bg-primary);
    }
    .fr-box.fr-document .fr-wrapper .fr-element hr {
      background: var(--color-bg-quaternary);
    }
  }
  .fr-tooltip {
    background: var(--color-bg-tooltip-brand);
    color: var(--color-fg-tooltip-brand);
  }

  .fr-toolbar .fr-command.fr-btn,
  .fr-popup .fr-command.fr-btn,
  .fr-modal .fr-command.fr-btn {
    color: var(--froala-button-text);
  }

  .fr-toolbar .fr-command.fr-btn svg path,
  .fr-popup .fr-command.fr-btn svg path,
  .fr-modal .fr-command.fr-btn svg path {
    fill: var(--froala-button-text);
  }

  .fr-toolbar .fr-command.fr-btn.fr-btn-active-popup,
  .fr-popup .fr-command.fr-btn.fr-btn-active-popup,
  .fr-modal .fr-command.fr-btn.fr-btn-active-popup {
    background: var(--color-bg-primary_hover);
  }

  .fr-toolbar .fr-command.fr-btn.fr-dropdown:after,
  .fr-popup .fr-command.fr-btn.fr-dropdown:after,
  .fr-modal .fr-command.fr-btn.fr-dropdown:after {
    border-top: 4px solid var(--color-fg-tertiary);
  }
  .fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active,
  .fr-popup .fr-command.fr-btn.fr-dropdown.fr-active,
  .fr-modal .fr-command.fr-btn.fr-dropdown.fr-active {
    fill: var(--color-fg-tertiary);
    background: var(--color-bg-primary_hover);
  }
  .fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:hover,
  .fr-popup .fr-command.fr-btn.fr-dropdown.fr-active:hover,
  .fr-modal .fr-command.fr-btn.fr-dropdown.fr-active:hover {
    background: var(--color-bg-primary_hover);
    fill: var(--color-fg-tertiary);
  }
  .fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:hover::after,
  .fr-popup .fr-command.fr-btn.fr-dropdown.fr-active:hover::after,
  .fr-modal .fr-command.fr-btn.fr-dropdown.fr-active:hover::after {
    border-top-color: var(--color-fg-tertiary);
  }
  .fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:after,
  .fr-popup .fr-command.fr-btn.fr-dropdown.fr-active:after,
  .fr-modal .fr-command.fr-btn.fr-dropdown.fr-active:after {
    border-top: none;
    border-bottom: 4px solid var(--color-border-primary);
  }
  .fr-toolbar .fr-command.fr-btn.fr-disabled,
  .fr-popup .fr-command.fr-btn.fr-disabled,
  .fr-modal .fr-command.fr-btn.fr-disabled {
    color: var(--color-text-disabled);
  }
  .fr-toolbar .fr-command.fr-btn.fr-disabled::after,
  .fr-popup .fr-command.fr-btn.fr-disabled::after,
  .fr-modal .fr-command.fr-btn.fr-disabled::after {
    border-top-color: var(--color-text-disabled);
  }

  .fr-toolbar .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active,
  .fr-toolbar .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active-tab,
  .fr-popup .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active,
  .fr-popup .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active-tab,
  .fr-modal .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active,
  .fr-modal .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active-tab {
    background: var(--color-bg-primary);
  }

  .fr-toolbar .fr-btn-grp .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path,
  .fr-toolbar .fr-more-toolbar .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path,
  .fr-toolbar .fr-buttons:not(.fr-tabs) .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path,
  .fr-popup .fr-btn-grp .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path,
  .fr-popup .fr-more-toolbar .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path,
  .fr-popup .fr-buttons:not(.fr-tabs) .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path,
  .fr-modal .fr-btn-grp .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path,
  .fr-modal .fr-more-toolbar .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path,
  .fr-modal .fr-buttons:not(.fr-tabs) .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path {
    fill: var(--color-fg-interactive-default);
  }
  .fr-toolbar.fr-disabled .fr-btn,
  .fr-toolbar.fr-disabled .fr-btn.fr-active,
  .fr-popup.fr-disabled .fr-btn,
  .fr-popup.fr-disabled .fr-btn.fr-active,
  .fr-modal.fr-disabled .fr-btn,
  .fr-modal.fr-disabled .fr-btn.fr-active {
    color: var(--color-text-disabled);
  }
  .fr-toolbar.fr-disabled .fr-btn.fr-dropdown::after,
  .fr-toolbar.fr-disabled .fr-btn.fr-active.fr-dropdown::after,
  .fr-popup.fr-disabled .fr-btn.fr-dropdown::after,
  .fr-popup.fr-disabled .fr-btn.fr-active.fr-dropdown::after,
  .fr-modal.fr-disabled .fr-btn.fr-dropdown::after,
  .fr-modal.fr-disabled .fr-btn.fr-active.fr-dropdown::after {
    border-top-color: var(--color-text-disabled);
  }
  .fr-desktop .fr-command:hover,
  .fr-desktop .fr-command:focus,
  .fr-desktop .fr-command.fr-btn-hover,
  .fr-desktop .fr-command.fr-expanded {
    color: var(--froala-button-text);
  }
  .fr-desktop .fr-command:hover:not(.fr-table-cell),
  .fr-desktop .fr-command:focus:not(.fr-table-cell),
  .fr-desktop .fr-command.fr-btn-hover:not(.fr-table-cell),
  .fr-desktop .fr-command.fr-expanded:not(.fr-table-cell) {
    background: var(--color-bg-primary_hover);
  }

  // specifically inserted because dark-theme overlay is different
  .fr-desktop .fr-dropdown-content .fr-command:hover:not(.fr-table-cell),
  .fr-desktop .fr-dropdown-content .fr-command:focus:not(.fr-table-cell),
  .fr-desktop .fr-dropdown-content .fr-command.fr-btn-hover:not(.fr-table-cell),
  .fr-desktop .fr-dropdown-content .fr-command.fr-expanded:not(.fr-table-cell) {
    background: var(--color-bg-primary_hover);
  }

  .fr-desktop .fr-command:hover::after,
  .fr-desktop .fr-command:focus::after,
  .fr-desktop .fr-command.fr-btn-hover::after,
  .fr-desktop .fr-command.fr-expanded::after {
    border-top-color: var(--color-fg-tertiary);
  }
  .fr-desktop .fr-command.fr-selected:not(.fr-table-cell),
  .fr-desktop .fr-command:active {
    color: var(--froala-button-text);
    background: var(--color-bg-primary_hover);
  }
  .fr-desktop .fr-command.fr-active:hover,
  .fr-desktop .fr-command.fr-active:focus,
  .fr-desktop .fr-command.fr-active.fr-btn-hover,
  .fr-desktop .fr-command.fr-active.fr-expanded {
    background: var(--color-bg-primary_hover);
  }
  .fr-desktop .fr-command.fr-active:active {
    background: var(--color-bg-primary_hover);
  }

  .fr-toolbar.fr-mobile .fr-command.fr-blink,
  .fr-popup.fr-mobile .fr-command.fr-blink {
    background: var(--color-bg-primary_hover);
  }

  .fr-command.fr-btn.fr-options.fr-btn-hover,
  .fr-command.fr-btn.fr-options:hover,
  .fr-command.fr-btn.fr-options:focus {
    border-left: solid 1px var(--color-border-secondary);
  }
  .fr-command.fr-btn + .fr-dropdown-menu {
    background: var(--color-bg-dropdown);
    box-shadow:
      0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }

  .fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a svg path {
    fill: var(--froala-button-text);
  }
  .fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a.fr-active {
    background: var(--color-bg-primary_hover);
  }
  .fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a.fr-disabled {
    color: var(--color-text-disabled);
  }

  .fr-command.fr-btn.fr-active + .fr-dropdown-menu {
    box-shadow:
      0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }

  .fr-modal {
    color: var(--color-text-primary);
  }

  .fr-modal .fr-modal-wrapper {
    background: var(--color-bg-modal);
    box-shadow:
      0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 6px 10px 0 rgba(0, 0, 0, 0.14),
      0 1px 18px 0 rgba(0, 0, 0, 0.12);
  }

  .fr-modal .fr-modal-wrapper .fr-modal-head {
    background: var(--color-bg-modal);
    border-bottom: 1px solid var(--color-border-primary);
  }

  .fr-modal .fr-modal-wrapper div.fr-modal-body button.fr-command {
    color: var(--color-fg-interactive-default);
  }

  .fr-modal .fr-modal-wrapper div.fr-modal-body button.fr-command:hover,
  .fr-modal .fr-modal-wrapper div.fr-modal-body button.fr-command:focus {
    background: var(--color-bg-primary_hover);
    color: var(--color-fg-interactive-default);
  }
  .fr-modal .fr-modal-wrapper div.fr-modal-body button.fr-command:active {
    background: var(--color-bg-primary_hover);
    color: var(--color-fg-interactive-default);
  }

  .fr-desktop .fr-modal-wrapper .fr-modal-head i:hover {
    background: var(--color-bg-primary_hover);
  }

  .fr-overlay {
    background: var(--color-bg-tooltip-brand);
  }

  .fr-popup {
    color: var(--color-text-primary);
    background: var(--color-bg-modal);
    box-shadow:
      0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 6px 10px 0 rgba(0, 0, 0, 0.14),
      0 1px 18px 0 rgba(0, 0, 0, 0.12);
  }

  .fr-popup .fr-input-focus {
    background: var(--color-bg-active);
  }

  .fr-popup .fr-input-line input[type='text'],
  .fr-popup .fr-input-line input[type='number'],
  .fr-popup .fr-input-line textarea {
    border: solid 1px var(--color-border-primary);
    color: var(--color-text-primary);
    background: var(--color-bg-primary);
  }
  .fr-popup .fr-input-line input[type='text']:hover,
  .fr-popup .fr-input-line input[type='number']:hover,
  .fr-popup .fr-input-line textarea:hover {
    border: solid 1px var(--color-border-primary);
  }
  .fr-popup .fr-input-line input[type='text']:focus,
  .fr-popup .fr-input-line input[type='number']:focus,
  .fr-popup .fr-input-line textarea:focus {
    border: solid 2px var(--color-border-interactive-default);
  }

  .fr-popup .fr-input-line input + label,
  .fr-popup .fr-input-line textarea + label {
    color: var(--color-text-secondary);
    background: var(--color-bg-primary);
  }
  .fr-popup .fr-input-line input.fr-not-empty + label,
  .fr-popup .fr-input-line textarea.fr-not-empty + label {
    color: var(--color-text-secondary);
  }

  .fr-popup .fr-buttons.fr-tabs {
    background-color: var(--color-bg-secondary);
  }

  .fr-popup .fr-action-buttons button.fr-command {
    color: var(--color-fg-interactive-default);
  }

  .fr-popup .fr-action-buttons button.fr-command:hover,
  .fr-popup .fr-action-buttons button.fr-command:focus {
    background: var(--color-bg-primary_hover);
    color: var(--color-fg-interactive-default);
  }
  .fr-popup .fr-action-buttons button.fr-command:active {
    background: var(--color-bg-primary_hover);
    color: var(--color-fg-interactive-default);
  }

  .fr-popup .fr-checkbox input:not(:checked) + span {
    border: solid 2px var(--color-border-primary);
  }
  .fr-popup .fr-checkbox input:not(:checked):active + span {
    background-color: var(--color-bg-active);
  }
  .fr-popup .fr-checkbox input:not(:checked):focus + span,
  .fr-popup .fr-checkbox input:not(:checked):hover + span {
    border-color: var(--color-border-primary);
  }
  .fr-popup .fr-checkbox input:checked + span {
    background: var(--color-fg-interactive-default);
    border: solid 2px var(--color-border-interactive-default);
  }

  .fr-popup .fr-checkbox input:checked:active + span {
    background-color: var(--color-bg-quaternary);
  }

  .fr-toolbar {
    color: var(--color-text-primary);
    background: var(--color-bg-primary);
    border: 1px solid var(--color-border-primary);
  }

  .fr-toolbar .fr-newline {
    display: none;
  }

  .fr-toolbar .fr-more-toolbar {
    background-color: var(--color-bg-primary_alt);
  }

  .fr-toolbar .fr-command.fr-btn.fr-open:not(:hover):not(:focus):not(:active) {
    background: var(--color-bg-primary_alt);
  }

  .fr-toolbar.fr-inline {
    box-shadow:
      0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 6px 10px 0 rgba(0, 0, 0, 0.14),
      0 1px 18px 0 rgba(0, 0, 0, 0.12);
  }

  .fr-toolbar.fr-bottom.fr-toolbar-open .fr-newline {
    background: var(--color-bg-primary);
  }

  .fr-separator {
    background: var(--color-bg-quaternary);
  }

  .fr-second-toolbar {
    border: 1px solid var(--color-border-primary);
    background: var(--color-bg-primary);
  }

  #fr-logo > span {
    color: var(--color-text-quarterary);
  }
  #fr-logo:hover > span,
  #fr-logo:focus > span {
    color: var(--color-fg-interactive-default);
  }
  #fr-logo:hover > svg .fr-logo,
  #fr-logo:focus > svg .fr-logo {
    fill: var(--color-fg-interactive-default);
  }

  .fr-view .fr-highlight-change,
  .fr-highlight-change > img,
  .fr-highlight-change > .fr-video > video,
  .fr-highlight-change > .fr-video > embed,
  .fr-highlight-change > .fr-video > iframe,
  .fr-highlight-change > .fr-embedly > .embedly-card > .embedly-card-hug > iframe,
  .fr-highlight-change > p,
  .fr-view .fr-highlight-change > span:not(.fr-tracking-deleted) {
    background-color: var(--froala-change-highlight-color);
  }

  .fr-highlight-change.fr-highlight-change > table td,
  .fr-highlight-change.fr-highlight-change > hr {
    border-color: var(--froala-change-highlight-color);
  }

  .fr-tracking-deleted.fr-tracking-deleted > table td,
  .fr-tracking-deleted.fr-tracking-deleted > hr {
    border-color: var(--color-border-error-solid);
  }

  .fr-tracking-deleted img,
  .fr-tracking-deleted > .fr-video > video,
  .fr-tracking-deleted > .fr-video > iframe,
  .fr-tracking-deleted > .fr-embedly > .embedly-card > .embedly-card-hug > iframe {
    background-color: var(--color-bg-error-primary);
  }

  .fr-view .fr-tracking-deleted {
    background-color: var(--color-bg-error-primary);
  }

  .fr-tracking-deleted img,
  .fr-highlight-change img {
    border: 2px solid var(--froala-change-highlight-color);
  }

  .fr-highlight-change #isPasted *:not(table):not(tbody):not(tr):not(td) {
    opacity: 0.5;
    background-color: var(--froala-change-highlight-color);
  }

  .fr-highlight-change #isPasted > table td {
    opacity: 0.5;
    border-color: var(--froala-change-highlight-color);
  }
}
