// preferred option
@mixin host-context {
  :host-context(.dark-theme) {
    @content;
  }

  @media (prefers-color-scheme: dark) {
    :host-context(body:not(.light-theme)) {
      @content;
    }
  }
}

// only for global definitions
@mixin global {
  body.dark-theme,
  body .dark-theme {
    @content;
  }

  @media (prefers-color-scheme: dark) {
    body:not(.light-theme) {
      @content;
    }
  }
}
