@use './mixins/dark-mode.mixins' as dark-mode;

ion-title {
  font-weight: var(--font-weight-normal);

  // We do not need that much padding (e.g. we are always using one button at max)
  padding-inline-start: 50px;

  // stylelint-disable-next-line declaration-block-no-redundant-longhand-properties
  padding-inline-end: 50px;
}

// INPUTS
ion-input {
  margin: var(--spacing-md);
  background: var(--color-bg-primary);
  border-radius: var(--radius-md);
  font-size: var(--font-size-body-normal);
}

// input with outline
ion-item.custom-outline-input {
  --inner-border-width: 0;
  --highlight-height: 0;
  --border-radius: var(--radius-md);
  --border-width: 1px;
  --min-height: 4.1rem;

  ion-input {
    margin-top: 0;
    margin-bottom: 0;
  }
}

ion-refresher {
  z-index: 0;
}

ion-buttons {
  ion-button.back-button {
    --padding-start: 0;
    --padding-end: 0;
  }

  ion-back-button {
    --min-width: 3.4rem; // To increase touchable area of back-button (STUDY-2319)
  }
}

ion-datetime {
  --background: var(--color-bg-modal);
  --background-rgb: 255, 255, 255;
  --wheel-highlight-background: var(--color-bg-secondary_alt);

  &::part(calendar-day active) {
    background-color: var(--color-bg-interactive-primary);
    color: var(--color-fg-white);
  }
}

@include dark-mode.global {
  ion-datetime {
    // The rgb values are necessary for ionic to compute certain shades
    --background-rgb: 22, 27, 38;
  }
}

ion-spinner {
  --color: var(--ion-text-color);
}
